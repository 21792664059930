<template>
     <div class="my_bid">
          <div class="title">{{ L['我的招标'] }}</div>
          <div class="wrap2 flex-col" @click="init_bid">
               <span class="word3">{{ L['发布招标'] }}</span>
          </div>
          <div class="nav_bar">
               <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
                    <el-tab-pane :label="L['全部']" name="0"></el-tab-pane>
                    <el-tab-pane :label="L['待平台审核']" name="10"></el-tab-pane>
                    <el-tab-pane :label="L['待投标']" name="20"></el-tab-pane>
                    <el-tab-pane :label="L['已结束']" name="30"></el-tab-pane>
                    <el-tab-pane :label="L['已终止']" name="40"></el-tab-pane>
               </el-tabs>
          </div>

          <div class="box1">

               <div class="flex-row">
                    <div class="flex_row_start_center">
                         <span class="info1">{{ L['招标主题：'] }}</span>
                         <div class="wrap3 flex-col">
                              <input type="text" v-model="title">
                         </div>
                    </div>

                    <div class="flex_row_start_center" style="margin-left: 20px;">
                         <span class="info1">{{ L['招标方式：'] }}</span>
                         <div class="flex-col">
                              <el-select v-model="mode" class="m-2" :placeholder="L['请选择']" size="small">
                                   <el-option v-for="item in modeType" :key="item.value" :label="item.label"
                                        :value="item.value">
                                   </el-option>
                              </el-select>
                         </div>
                    </div>

                    <div class="flex_row_start_center" style="margin-left: 20px;">
                         <span class="info1">{{ L['招标编号：'] }}</span>
                         <div class="wrap3 flex-col">
                              <input type="text" v-model="code">
                         </div>
                    </div>
               </div>


               <div class="flex-row" style="margin-top: 20px;">

                    <div class="flex_row_start_center">
                         <span class="info1">{{ L['招标类型：'] }}</span>
                         <div class="flex-col">
                              <el-select v-model="type" class="m-2" :placeholder="L['请选择']" size="small">
                                   <el-option v-for="item in bidType" :key="item.value" :label="item.label"
                                        :value="item.value">
                                   </el-option>
                              </el-select>
                         </div>
                    </div>

                    <div class="wrap7 flex-col" @click="confirm">
                         <span class="info3">{{ L['搜索'] }}</span>
                    </div>
                    <div class="wrap8 flex-col" @click="reset">
                         <span class="info4">{{ L['重置'] }}</span>
                    </div>
               </div>




          </div>

          <div class="box22" v-if="true">
               <div class="box33">
                    <el-table :data="bidList" style="width: 100%" @sort-change="sort">


                         <el-table-column :label="L['招标主题']" width="290" align="center">
                              <template #default="scope">
                                   <div class="flex_row_start_center bid_info">
                                        <div class="flex_column_center_center">
                                             <img src="@/assets/bid.png">
                                             <span class="b_text">{{ scope.row.typeValue }}</span>
                                        </div>
                                        <div class="b1_text">
                                             {{ scope.row.title }}
                                        </div>
                                   </div>
                              </template>
                         </el-table-column>



                         <el-table-column prop="code" :label="L['招标编号']" width="180" align="center" />

                         <el-table-column prop="priceEndTime" :label="L['招标方式']" width="180" align="center">
                              <template #default="scope">
                                   <div class="bid_way">
                                        <div>{{ scope.row.modeValue }}</div>
                                        <div class="invited" @click="openInvited(scope.row.bidId)"
                                             v-if="scope.row.mode == 2">{{ L['受邀投标方'] }} ></div>
                                   </div>
                              </template>
                         </el-table-column>
                         <el-table-column prop="deadline" :label="L['招标截止时间']" width="160" align="center" sortable />
                         <el-table-column prop="openTime" :label="L['开标时间']" width="160" align="center" sortable />
                         <el-table-column :label="L['状态']" width="180" align="center">
                              <template #default="scope">
                                   <div>{{ scope.row.stateValue }}</div>
                                   <div class="refuseReason" v-if="scope.row.refuseReason">
                                        <el-scrollbar>
                                             <span>{{ scope.row.refuseReason }}</span>
                                             <span></span>
                                        </el-scrollbar>
                                   </div>
                              </template>
                         </el-table-column>


                         <el-table-column :label="L['操作']" width="180" align="center" fixed="right">
                              <template #default="scope">
                                   <div class="TextGroup1 flex_column_center_center">
                                        <div class="">
                                             <div class="txt777" @click="toDetail(scope.row.bidId)">{{ L['查看详情'] }}</div>
                                             <div class="txt777" v-if="scope.row.state == 10"
                                                  @click="toDelete(scope.row.bidId)">{{ L['删除'] }}</div>
                                             <div class="txt777" v-if="scope.row.state == 20"
                                                  @click="terminateBid(scope.row.bidId)">{{ L['终止招标'] }}</div>
                                             <div class="txt777" v-if="scope.row.state == 40"
                                                  @click="seeterminateBid(scope.row)">{{ L['查看终止公告'] }}</div>

                                             <div class="txt777" v-if="scope.row.winId" @click="seeWinBid(scope.row)">
                                                  {{ L['查看中标公告'] }}</div>

                                             <div class="txt777" @click="initWinBid(scope.row)"
                                                  v-if="scope.row.state == 32 && !scope.row.winId">
                                                  {{ L['发布中标公告'] }}</div>
                                        </div>
                                   </div>
                              </template>
                         </el-table-column>
                    </el-table>
               </div>


               <!-- 分页 -->
               <div class="flex_row_center_center sld_pagination">
                    <el-pagination @current-change="handleCurrentChange" v-model:currentPage="page.current"
                         :page-size="page.pageSize" layout="prev, pager, next, jumper" :total="page.total"
                         :hide-on-single-page="true">
                    </el-pagination>
               </div>
          </div>

          <div class="bid_empty flex_column_center_center" v-else>
               <img src="@/assets/inquiry/inq_empty.png" alt="">
               <p>{{ L['暂无报价'] }}</p>
          </div>

          <invited :visi="invite_visible" ref="invited"></invited>
     </div>
</template>

<script>
import { ref, reactive, toRefs, getCurrentInstance, onMounted } from 'vue'
import { inqUtils } from '@/utils/inquiry_utils.js'
import { useRouter } from 'vue-router'
import { ElMessage } from 'element-plus'
import invited from '@/views/bid/component/invited'
export default {
     components: {
          invited
     },
     setup(props) {
          const activeName = ref('0')
          const { formatTime, hp } = inqUtils()
          const { proxy } = getCurrentInstance()
          const L = proxy.$getCurLanguage()
          const param = reactive({
               current: 1,
               pageSize: 10,
               sort: 0
          })

          const router = useRouter()
          const invite_visible = ref(false)
          const bidList = ref([])

          const modeType = ref([
               { label: L['全部'], value: 0 },
               { label: L['公开招标'], value: 1 },
               { label: L['邀请招标'], value: 2 },
          ])

          const bidType = ref([
               { label: L['全部'], value: 0 },
               { label: L['货物招标'], value: 1 },
               { label: L['工程招标'], value: 2 },
               { label: L['服务招标'], value: 3 },
          ])

          const searchDTO = reactive({
               state: 0,
               title: '',
               type: '',
               code: '',
               mode: '',
          })

          const page = reactive({
               current: 1,
               pageSize: 10,
               total: 20
          })

          const confirm = () => {
               for (let i in searchDTO) {
                    if (searchDTO[i]) {
                         param[i] = searchDTO[i]
                    } else {
                         delete param[i]
                    }
               }
               param.current = 1
               getList()
          }

          const reset = () => {

               for (let i in searchDTO) {
                    if (i != 'state') {
                         delete param[i]
                         searchDTO[i] = ''
                    }
               }
               param.current = 1

               getList()
          }



          const toDetail = (bidId) => {
               let newWin = router.resolve({
                    path: '/bid/detail',
                    query: {
                         bidId
                    }
               })

               window.open(newWin.href, '_blank')
          }


          const openInvited = (bidId) => {
               proxy.$refs.invited.openList(bidId)
          }

          const toEdit = () => {
               router.push({
                    path: '/bid/openBid',
               })
          }



          const handleClick = (tab) => {
               param.current = 1
               param.state = tab.props.name
               getList()
          }


          const getList = () => {
               proxy.$get('v3/business/front/bid/list', param).then(res => {
                    if (res.state == 200) {
                         bidList.value = res.data.list
                         page.current = res.data.pagination.current
                         page.pageSize = res.data.pagination.pageSize
                         page.total = res.data.pagination.total
                    }
               })
          }

          const toDelete = (bidId) => {
               proxy.$confirm(L['确认删除该招标？'], {
                    confirmButtonText: L['确定'],
                    cancelButtonText: L['取消'],
                    type: 'warning',
                    center: true
               }).then(res => {
                    proxy.$post('v3/business/front/bid/delete', { bidId }).then(res => {
                         if (res.state == 200) {
                              ElMessage.success(res.msg)
                              getList()
                         }
                    })
               })
          }

          const endQuotation = (bidId) => {
               proxy.$confirm(L['确认结束报价？结束报价后将不再接收针对该询盘的报价。'], {
                    confirmButtonText: L['确定'],
                    cancelButtonText: L['取消'],
                    type: 'warning',
                    center: true
               }).then(res => {
                    proxy.$post('v3/business/front/purchaseInquire/endQuote', { bidId }).then(res => {
                         if (res.state == 200) {
                              ElMessage.success(res.msg)
                              getList()
                         }
                    })
               })
          }


          const handleCurrentChange = (e) => {
               param.current = e
               getList()
          }

          const terminateBid = (bidId) => {
               router.push({
                    path: '/bid/terBid',
                    query: {
                         bidId
                    }
               })
          }

          const seeterminateBid = (scope) => {
               let { endId, bidId } = scope
               router.push({
                    path: '/bid/terBidNoti',
                    query: {
                         bidId, endId
                    }
               })
          }

          const seeWinBid = (scope) => {
               let { winId, bidId } = scope
               router.push({
                    path: '/bid/winBidNoti',
                    query: {
                         winId,
                         bidId
                    }
               })
          }

          const initWinBid = (scope) => {
               let { bidId } = scope
               router.push({
                    path: '/bid/winBid',
                    query: {
                         bidId
                    }
               })
          }

          const init_bid = () => {
               router.push('/bid/openBid')
          }

          const titleFormatter = (row, column, cellValue, index) => {
               return cellValue.length > 10 ? cellValue.slice(0, 10) + '...' : cellValue
          }


          const sort = (e) => {
               switch (e.prop) {
                    case 'deadline': {
                         if (e.order == "ascending") {
                              param.sort = 11
                         } else {
                              param.sort = 12

                         }
                         break
                    }
                    case 'openTime': {
                         if (e.order == "ascending") {
                              param.sort = 21
                         } else {
                              param.sort = 22
                         }
                         break
                    }

                    case null: {
                         param.sort = 0
                    }

               }

               getList()
          }



          onMounted(() => {
               getList()
          })

          return {
               sort,
               activeName,
               handleClick,
               page,
               ...toRefs(searchDTO),
               bidList,
               init_bid,
               toDetail,
               toEdit,
               toDelete,
               endQuotation,
               confirm,
               reset,
               titleFormatter,
               hp,
               invite_visible,
               openInvited,
               bidType,
               modeType,
               terminateBid,
               seeterminateBid,
               seeWinBid,
               initWinBid,
               handleCurrentChange,
               L
          }
     }
}


</script>

<style lang="scss">
input {
     height: 28px;
     outline: none;
     border: none;

}


.refuseReason {
     margin-top: 10px;
     max-height: 70px;
}

.my_bid {
     .is-active {
          color: $colorMain;
     }

     .el-tabs__active-bar {
          background-color: $colorMain;
     }

     .el-tabs__item:hover {
          color: $colorMain;
          cursor: pointer;
     }

     .el-date-editor--daterange.el-input__inner {
          width: 250px;
     }

     .el-date-editor .el-range-separator {
          width: 7%;
     }

     .wrap6 .el-input__inner {
          border: none;
     }

     .has-gutter tr th {
          background: #F7F8FA;
     }

     .is-leaf {
          background: #F7F8FA;

     }
}
</style>

<style lang="scss" scoped>
.my_bid {
     float: left;
     width: 1015px;
     background-color: #fff;
     margin-left: 15px;
     padding: 20px;
     position: relative;

     .title {
          font-size: 16px;
          font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          font-weight: 500;
          color: #333333;
          font-weight: bold;
     }

     .nav_bar {
          margin-top: 20px;
     }

     .bid_info {
          img {
               width: 24px;
               height: 24px;
          }

          .b_text {
               font-size: 12px;
               font-family: Microsoft YaHei;
               font-weight: 400;
               color: #0854EC;
               white-space: nowrap;
          }

          .b1_text {
               font-size: 14px;
               font-family: Microsoft YaHei;
               font-weight: 400;
               color: #333333;
               text-align: left;
               margin-left: 10px;
          }
     }

     .bid_way {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;

          .invited {
               margin-top: 5px;
               font-size: 14px;
               font-family: Microsoft YaHei;
               font-weight: 400;
               color: $colorMain;
               cursor: pointer;
          }
     }


     .wrap2 {
          background-color: rgba(40, 95, 222, 1);
          border-radius: 4px;
          height: 36px;
          width: 120px;
          position: absolute;
          right: 20px;
          top: 30px;
          cursor: pointer;

          .word3 {
               color: rgba(255, 255, 255, 1);
               font-size: 16px;
               line-height: 16px;
               display: block;
               margin: 10px 0 0 28px;
          }
     }

     .box1 {
          margin-top: 20px;

          .info1 {
               white-space: nowrap;
               color: rgba(51, 51, 51, 1);
               font-size: 14px;
               font-family: SourceHanSansCN-Regular;
          }

          .wrap3 {
               background-color: rgba(255, 255, 255, 1);
               width: 220px;
               height: 30px;
               background: #FFFFFF;
               border: 1px solid #C9C9C9;
               border-radius: 3px;
               border: 1px solid rgba(201, 201, 201, 1);
               /* margin-left: 10px; */
               padding: 0 10px;
          }

          .word3 {
               white-space: nowrap;
               color: rgba(51, 51, 51, 1);
               font-size: 14px;
               font-family: SourceHanSansCN-Regular;
               margin: 0px 0 0 22px;
          }

          .wrap4 {
               background-color: rgba(255, 255, 255, 1);
               height: 36px;
               border: 1px solid rgba(201, 201, 201, 1);
               margin-left: 15px;
               width: 115px;

               .bd3 {
                    width: 95px;
                    height: 18px;
                    margin: 8px 0 0 10px;

                    .word4 {
                         width: 49px;
                         height: 12px;

                         color: rgba(153, 153, 153, 1);
                         font-size: 12px;
                         font-family: SourceHanSansCN-Regular;
                         text-align: left;
                         white-space: nowrap;
                         line-height: 12px;
                         margin-top: 4px;
                         display: block;
                    }

                    .icon1 {
                         width: 18px;
                         height: 18px;
                    }
               }
          }

          .word5 {
               width: 8px;

               color: rgba(153, 153, 153, 1);
               font-size: 14px;
               font-family: SourceHanSansCN-Regular;
               text-align: left;
               white-space: nowrap;
               line-height: 14px;
               display: block;
               margin: 17px 0 0 19px;
          }

          .wrap5 {
               background-color: rgba(255, 255, 255, 1);
               height: 36px;
               border: 1px solid rgba(201, 201, 201, 1);
               margin-left: 17px;
               width: 115px;

               .section1 {
                    width: 96px;
                    height: 18px;
                    margin: 9px 0 0 11px;

                    .info2 {
                         width: 49px;
                         height: 12px;

                         color: rgba(153, 153, 153, 1);
                         font-size: 12px;
                         font-family: SourceHanSansCN-Regular;
                         text-align: left;
                         white-space: nowrap;
                         line-height: 12px;
                         margin-top: 2px;
                         display: block;
                    }

                    .label3 {
                         width: 18px;
                         height: 18px;
                    }
               }
          }

          .txt1 {
               color: rgba(51, 51, 51, 1);
               font-size: 14px;
               display: block;
               margin: 0px 0 0 28px;
          }

          .wrap6 {
               background-color: rgba(255, 255, 255, 1);
               height: 36px;
               border: 1px solid rgba(201, 201, 201, 1);
               width: 100px;
               margin: 0px 0 0 11px;

               .layer3 {
                    width: 85px;
                    height: 11px;
                    margin: 12px 0 0 8px;

                    .txt2 {
                         width: 37px;
                         height: 11px;

                         color: rgba(153, 153, 153, 1);
                         font-size: 12px;
                         font-family: SourceHanSansCN-Regular;
                         text-align: left;
                         white-space: nowrap;
                         line-height: 12px;
                         display: block;
                    }

                    .label4 {
                         width: 13px;
                         height: 7px;
                         margin-top: 3px;
                    }
               }
          }

          .wrap7 {
               background-color: rgba(40, 95, 222, 1);
               border-radius: 4px;
               height: 32px;
               width: 68px;
               margin: 3px 0 0 31px;
               cursor: pointer;

               .info3 {
                    width: 28px;
                    height: 13px;

                    color: rgba(255, 255, 255, 1);
                    font-size: 14px;
                    font-family: SourceHanSansCN-Regular;
                    text-align: left;
                    white-space: nowrap;
                    line-height: 14px;
                    display: block;
                    margin: 9px 0 0 19px;
               }
          }

          .wrap8 {
               border-radius: 4px;
               height: 32px;
               border: 1px solid rgba(40, 95, 222, 1);
               width: 68px;
               margin: 3px 0 0 20px;
               cursor: pointer;

               .info4 {
                    width: 29px;
                    height: 13px;

                    color: rgba(40, 95, 222, 1);
                    font-size: 14px;
                    font-family: SourceHanSansCN-Regular;
                    text-align: left;
                    white-space: nowrap;
                    line-height: 14px;
                    display: block;
                    margin: 9px 0 0 19px;
               }
          }
     }

     .box22 {
          margin-top: 30px;

          .box33 {
               border: 1px solid #DCDCDC;

          }

          .text2 {
               font-size: 12px;
               font-family: Source Han Sans CN;
               font-weight: 400;
               color: #FA0606;
          }

          .TextGroup12 {
               .txt78 {
                    color: rgba(51, 51, 51, 1);
                    font-size: 12px;
                    display: block;
               }
          }


          .TextGroup1 {

               .txt777 {
                    color: rgba(51, 51, 51, 1);
                    font-size: 14px;
                    display: block;
                    cursor: pointer;

                    &:hover {
                         color: rgba(40, 95, 222, 1);

                    }

                    &.blue {
                         color: rgba(40, 95, 222, 1) !important;
                    }
               }

               .main3 {
                    margin-top: 4px;

                    .txt8 {
                         color: rgba(51, 51, 51, 1);
                         font-size: 12px;
                         cursor: pointer;

                         &:hover {
                              color: rgba(40, 95, 222, 1);

                         }
                    }

                    .word15 {
                         color: rgba(51, 51, 51, 1);
                         font-size: 12px;
                         cursor: pointer;

                         &:hover {
                              color: rgba(40, 95, 222, 1);

                         }
                    }
               }
          }
     }

     .bid_empty {

          padding-top: 140px;
          padding-bottom: 200px;

          img {
               width: 120px;
               height: 119px;
          }

          p {
               font-size: 14px;
               font-family: Source Han Sans CN;
               font-weight: 400;
               color: #333333;
               margin-top: 34px;
          }
     }

}
</style>